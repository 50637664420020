import React, {useEffect, useState} from "react";
import {
    Grid,
    Paper,
    Group,
    ThemeIcon,
    Container,
    Button,
    MediaQuery,
    TextInput,
    Title,
    Text,
    Avatar, LoadingOverlay
} from "@mantine/core";
import api from "../../../services/api";
import DataTable from "react-data-table-component";



export default function ListGames({history}) {
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(true);
    let [games, setGames] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');

    function getGames(page) {
        let array = [];
        api.get(`/admin/games?page=${page}&order=asc&term=title`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            response.data.data.map( index => {
                let streamLink = index.twitch_thumbnail_url.replace(
                    "{width}x{height}",
                    "300x300"
                );
                index.twitch_thumbnail_url = streamLink;
                array.push(index);

            })
            setGames(array);
            setPage(response.data.page);
            setCount(response.data.total)
            setLoading(false);
        })
    }

    useEffect(() => {
        getGames(1);
    }, []);

    function SearchItem() {
        setLoading(true);
        let array = [];
        api
            .get(`/admin/games-search/?term=${search}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                response.data.data.map( index => {
                    let streamLink = index.twitch_thumbnail_url.replace(
                        "{width}x{height}",
                        "300x300"
                    );
                    index.twitch_thumbnail_url = streamLink;
                    array.push(index);

                })
                setGames(array);
                setPage(response.data.page);
                setCount(response.data.total)
                setLoading(false);
            });
    }



    const handlePageChange = page => {
        getGames(page);
    };


    const columns = [
        {
            name: 'Título do Jogo',
            cell: row => {
                return (
                    <Group position="start">
                        <Avatar src={row.twitch_thumbnail_url}/>
                        <Text size="sm">{row.title}</Text>
                    </Group>

                )
            }
        },
        {
            name: 'ID do Jogo',
            selector: row => row.game_id
        },
        // {
        //     name: 'Ação',
        //     center: true,
        //     width: '200px',
        //     cell: row => {
        //         return (
        //             <Group position="apart">
        //                 <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
        //                            style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
        //                            onClick={() => history.push(`/streams/${row.twitch_account_id}`)}>
        //                     <IconEye size={18} color="#8F95B2"/>
        //                 </ThemeIcon>
        //                 <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
        //                            style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
        //                            onClick={() => changeOpen(row.twitch_account_id)}>
        //                     <IconTrash size={18} color="#8F95B2"/>
        //                 </ThemeIcon>
        //             </Group>
        //         )
        //     }
        // },
    ];

    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Grid mb="md">
                                <Grid.Col xs={12} md={6}>
                                    <Title order={2} c="brand.6">Jogos na Plataforma</Title>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <Group position="right">
                                        <TextInput style={{width: 300, margin: 0}}
                                                   placeholder='Busque aqui pelo nome do jogo'
                                                   onChange={e => setSearch(e.target.value)}/>
                                        <MediaQuery smallerThan={600} styles={{width:'100%'}}>
                                            <Button style={{width: 200, margin: 0}}
                                                    onClick={() => SearchItem()}> Buscar</Button>
                                        </MediaQuery>
                                    </Group>
                                </Grid.Col>

                            </Grid>

                            <DataTable theme="mosTheme" columns={columns}
                                       data={games}
                                       pagination
                                       paginationServer
                                       paginationPerPage={20}
                                       progressPending={loading}
                                       paginationDefaultPage={page}
                                       paginationTotalRows={count}
                                       onChangePage={handlePageChange}
                                       paginationComponentOptions={{
                                           noRowsPerPage: true,
                                           rangeSeparatorText: 'de',
                                       }}
                            />
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Container>
        )
    }
}