import React, {useEffect, useState} from "react";
import {
    Button,
    Grid,
    Paper,
    Container,
    Title,
    Group,
    TextInput,
    NumberInput,
    Stack,
    Modal,
    Text,
    Switch,
    Divider,
    Select,
    Avatar,
    LoadingOverlay
} from '@mantine/core'
import "./style.scss";
import api from "../../services/api";
import ParseBadge from "../../Helpers/parseBadges";

export default function UsersDetails({match, history}) {
    const userId = match.params.id;
    let token = localStorage.getItem('authorization');
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [opened, setOpened] = useState(false);
    const [badges, setBadges] = useState([]);
    const [value, setValue] = useState([])
    const [ adminBadge, setAdminBadge] = useState('');
    const [scoreData, setScoreData] = useState({});
    const [bgsPoints, setBgsPoints] = useState(0);
    const [nuuvemPoints, setNuuvemPoints] = useState(0);
    const [wheelTickets, setWheelTickets] = useState(0);

    function getBgsPoints(){
       api.get(`/admin/bgs/users/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
           }
         }).then( response => {
             setBgsPoints(response.data.points);
        })
    }

     function editBgsPoints(){
         api.put('/admin/bgs/points', {
             userID: match.params.id,
             points: bgsPoints
       }).then( response => {
             getBgsPoints();
        })
     }

    function getNuuvemPoints(){
        api.get(`/admin/nuuvem/users/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then( response => {
            setNuuvemPoints(response.data.points);
        })
    }

    // function editNuuvemPoints(){
    //     api.post('/admin/nuuvem/points', {
    //         userID: match.params.id,
    //         points: nuuvemPoints
    //     }).then( response => {
    //         getNuuvemPoints();
    //     })
    // }

    function getWheelTickets(){
        api.get(`/admin/user-tickets/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            setWheelTickets(response.data.value);
        })
    }

    function getAdminBadges(){
        api.get(`/admin/badge/index`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            response.data.map( index => {
                value.push({value: index.id, label: index.name})
            })
        })
    }

    function getUser() {
        api.get(`/admin/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
                setUser(response.data);
            }
        )
    }

    function getBadges(){
        api.get(`/admin/badge/user/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            setBadges(response.data)
        })
    }



    useEffect(() => {
        getAdminBadges();
        getUser();
        getBadges();
        // getNuuvemPoints();
        getBgsPoints();
        getWheelTickets();
        setLoading(false);

    }, []);

    function GiveBadge(){
        api.post(`/admin/badge/user/set-user`, {
            "email": user.email,
            "badgeID": adminBadge
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then( response => {
            getBadges()
        })
    }

    function GetMMRScore(){
        setLoading(true);
        api.get(`/admin/newScoreCheck/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then( response => {
            setScoreData(response.data)
            setLoading(false);
        })
    }

    function updateAvatarImage(){
        api.put('/admin/avatar', {
            userID: userId
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then( response => {
            getUser();
        })
    }

    function deleteUser() {
        api.delete(`/admin/users/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
                console.log(response);
                history.push('/usuarios');
            }
        )
    }

    function disconnectTwitch() {
        api.patch(`/admin/users/disconnect-twitch/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
                getUser();
            }
        )
    }

    function DeleteBadge(id){
        const email = user.email;

        api.delete('/admin/badge/user/set-user', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                "email": email,
                "badgeID": id
            }
        }).then(response => {
            getBadges()
        });

    }

    function UpdateWheelTickets(){
        api.put('/admin/user-tickets', {
            userID: userId,
            points: wheelTickets
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            } }).then( () => {
                getWheelTickets();
        })
    }


    function EditInfo(type, value) {
        setLoading(true);
        console.log(type, value)
        api.patch(`/admin/users/${match.params.id}`, {
            [type]: value
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(() => {
                setLoading(false);
                getUser();
            }
        )
    }

    if (loading) {
        return  <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>

                <Modal
                    opened={opened}
                    onClose={() => setOpened(false)}
                >
                    <center>
                        <Title order={3}>Deseja excluir o usuário {user.name}?</Title>
                        <Text size='sm' style={{marginTop:32, marginBottom:32}}>Esta ação não poderá ser desfeita</Text>
                    </center>

                    <Group position='center'>
                        <Button onClick={() => setOpened(false)} style={{backgroundColor:'#3C4055'}}>Cancelar</Button>
                        <Button onClick={() => deleteUser()} style={{backgroundColor:'#9D1414'}}>Excluir usuário</Button>
                    </Group>
                </Modal>

                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Title order={2} style={{marginBottom:32}}>Detalhes do usuário</Title>

                            <Grid>
                                <Grid.Col xs={12} md={5}>
                                    <TextInput mb="sm"  label="Nome" value={user.name} readOnly/>
                                    <TextInput mb="sm"  label="Username" value={user.username} readOnly/>
                                    <TextInput mb="sm" label="E-mail" value={user.email} readOnly/>
                                    <TextInput mb="sm" label="Usuário Twitch" value={(user.twitch_display_name) ? user.twitch_display_name : 'Desconectado'} readOnly/>
                                    <TextInput mb="sm" label="Chave Pix" value={user.pix_key} readOnly/>
                                    <TextInput mb="sm" label="Telefone" value={user.phone} readOnly/>
                                    <TextInput mb="sm" label="ID da Twitch" value={user.twitch_account_id} hideControls readOnly/>
                                    <TextInput  label="ID do Usuário" value={user.id} hideControls readOnly/>
                                </Grid.Col>

                                <Grid.Col xs={12} md={5}>
                                    <TextInput mb="sm" label="Nível" value={user.level} readOnly/>

                                    <TextInput mb="sm" label="Experiência" value={user.experience} readOnly/>

                                    <Grid align="flex-end">
                                        <Grid.Col xs={12} md={8}>
                                            <NumberInput label="MMR" value={user.score} hideControls onChange={e => setUser({...user, score: e})}/>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={4}>
                                            <Button fullWidth
                                                    onClick={() => EditInfo('score', user.score)}>Editar</Button>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={8}>
                                            <NumberInput label="Moedas" value={user.coins} hideControls onChange={e => setUser({...user, coins: e})}/>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={4}>
                                            <Button fullWidth
                                                    onClick={() => EditInfo('coins', user.coins)}>Editar</Button>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={8}>
                                            <NumberInput label="Tickets da Roleta" value={wheelTickets} hideControls onChange={e => setWheelTickets(e)}/>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={4}>
                                            <Button fullWidth
                                                    onClick={() => UpdateWheelTickets()}>Editar</Button>
                                        </Grid.Col>


                                        <Grid.Col xs={12} md={8}>
                                            <NumberInput label="Tickets de Lives" value={user.purchased_raids} hideControls onChange={e => setUser({...user, purchased_raids: e})}/>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={4}>
                                            <Button fullWidth
                                                    onClick={() => EditInfo('purchased_raids', user.purchased_raids)}>Editar</Button>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={8}>
                                            <NumberInput  label="Tickets de Follows" value={user.purchased_followers} hideControls onChange={e => setUser({...user, purchased_followers: e})}/>
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={4}>
                                            <Button fullWidth
                                                    onClick={() => EditInfo('purchased_followers', user.purchased_followers)}>Editar</Button>
                                        </Grid.Col>

                                    </Grid>

                                </Grid.Col>

                                <Grid.Col xs={12} md={2}>
                                    <Switch
                                        mt="lg"
                                        checked={user.suitable_referral_link}
                                        onChange={() => EditInfo('suitable_referral_link', !user.suitable_referral_link)}
                                        color="purple"
                                        size="sm"
                                        label="Indicação de usuário ativa"
                                    />

                                    <Stack>
                                        <Button onClick={() => disconnectTwitch()} style={{ marginTop:16}}>Desconectar Twitch</Button>
                                        <Button onClick={() => setOpened(true)} style={{backgroundColor:'#9D1414'}}>Excluir Usuário</Button>

                                        <Group position="apart">
                                            <Avatar size={64} src={user.avatar_twitch} radius={64}/>
                                            <Button onClick={() => updateAvatarImage()}>Atualizar Avatar</Button>
                                        </Group>

                                    </Stack>
                                </Grid.Col>

                                <Grid.Col xs={12}>
                                    <Divider my="sm" variant="dotted" />

                                    <Title order={2} style={{marginBottom:32}}>Badges MOS.GG</Title>
                                    <Paper style={{display: 'flex', flexDirection:'row'}}>
                                        {(badges.length === 0) ?
                                            <Text size="sm">Você não possui nenhuma badge ainda. Mas não se preocupe! Em breve terão várias oportunidades!</Text> :
                                            badges.map(index => {
                                                return(
                                                    <Paper key={index.badge_id} p="sm" style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                                         <ParseBadge name={index.name} imageId={index.image} description={index.description} />
                                                        <Button style={{marginTop:16}} onClick={() => DeleteBadge(index.badge_id)}>Excluir</Button>
                                                    </Paper>
                                                )
                                            })
                                        }
                                    </Paper>
                                    <Grid>
                                        <Grid.Col xs={12} md={9}>
                                            <Select
                                                label="Badge"
                                                placeholder="Selecione"
                                                data={value}
                                                value={adminBadge}
                                                onChange={e => setAdminBadge(e)}
                                            />
                                        </Grid.Col>

                                        <Grid.Col xs={12} md={3}>
                                            <Button fullWidth mt="xl" onClick={() => GiveBadge()}>Atribuir Badge</Button>
                                        </Grid.Col>
                                    </Grid>

                                </Grid.Col>

                              <Grid.Col xs={12}>
                                  <Divider my="sm" variant="dotted" />

                                    <Title order={2} style={{marginBottom:32}}>Evento BGS</Title>

                                   <Group style={{marginBottom: 16}} position='apart'>

                                      <NumberInput style={{width: '80%'}} label="Atribuir pontos ao usuário" value={bgsPoints} hideControls onChange={e => setBgsPoints(e)}/>

                                        <Button style={{marginTop: 24, width: '18%'}}
                                               onClick={() => editBgsPoints()}>Editar</Button>
                                  </Group>
                                </Grid.Col>

                                {/*<Grid.Col xs={12}>*/}
                                {/*    <Divider my="sm" variant="dotted" />*/}

                                {/*    <Title order={2} style={{marginBottom:32}}>Evento Nuuvem Granblue Fantasy</Title>*/}

                                {/*    <Group style={{marginBottom: 16}} position='apart'>*/}

                                {/*        <NumberInput style={{width: '80%'}} label="Atribuir pontos ao usuário" value={nuuvemPoints} hideControls onChange={e => setNuuvemPoints(e)}/>*/}

                                {/*        <Button style={{marginTop: 24, width: '18%'}}*/}
                                {/*                onClick={() => editNuuvemPoints()}>Editar</Button>*/}
                                {/*    </Group>*/}
                                {/*</Grid.Col>*/}


                                <Grid.Col xs={12}>
                                    <Divider my="sm" variant="dotted" />
                                    <Button fullWidth mt="md" onClick={() => GetMMRScore()}>Clique para conferir o MMR</Button>
                                    {(scoreData.score_atual)?
                                        <Grid>
                                           <Grid.Col xs={12}>
                                               <Title order={2} style={{marginBottom:16, marginTop:16}}>Cálculo de MMR</Title>
                                           </Grid.Col>
                                            <Grid.Col xs={12} md={6}>
                                                <Text size="sm">Score Atual: {scoreData.score_atual}</Text>
                                                <Text size="sm">Score calculado: {scoreData.total_score_from_check}</Text>
                                            </Grid.Col>

                                            <Grid.Col xs={12} md={6}>
                                                <Text size="sm">Pontos de Badge: {scoreData.pointsBadge}</Text>
                                                <Text size="sm">Pontos de Nível: {scoreData.pointsLevel}</Text>
                                                <Text size="sm">Pontos da Missão Diária: {scoreData.pointsDaily}</Text>
                                                <Text size="sm">Pontos das Respostas EAD: {scoreData.pointsAnswers}</Text>
                                                <Text size="sm">Pontos de Raids: {scoreData.pointsRaids}</Text>
                                                <Text size="sm">Pontos de Follow: {scoreData.pointsFollowed}</Text>
                                                <Text size="sm">Pontos de Lives: {scoreData.pointsLives}</Text>
                                            </Grid.Col>
                                        </Grid>


                                        : ''}
                                </Grid.Col>

                            </Grid>

                        </Paper>
                    </Grid.Col>
                </Grid>

            </Container>
        )
    }
}