import React from "react";
import {Container,Grid, Paper, Textarea, TextInput, Title, Button} from "@mantine/core";
import {useForm} from "@mantine/form";
import api from "../../../services/api";
import NotificationFactory from "../../../Helpers/notificationFactory";
import {DateInput} from "@mantine/dates";


export default function CreateEvent({history}){
    const token = sessionStorage.getItem('user_token');
    const form = useForm({
        initialValues: {
            title: '',
            description: '',
            start_date: new Date(),
            end_date: new Date()
        },
    });

    async function handleSubmit(){
        const formData = form.values;
        try{
            await api.post('/admin/events/create', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            NotificationFactory('success', 'Novo evento cadastrado','Seu novo evento foi cadastrado com sucesso');
            history.push('/eventos');
        }catch(e){
            console.log(e.response.data);
            NotificationFactory('error', 'Erro ao criar evento','Não foi possível concluir a ação');
        }
    }

    return(
        <Container fluid>
            <Paper shadow="md" radius="md" p="md">
                <Title order={2} c="brand.6" mb="md">Cadastrar Novo Evento</Title>
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Grid>
                        <Grid.Col xs={12}>
                            <TextInput
                                placeholder="Título do Evento"
                                label="Título do Evento"
                                withAsterisk
                                {...form.getInputProps('title')}
                            />
                        </Grid.Col>

                        <Grid.Col xs={12}>
                            <Textarea
                                placeholder="Descrição"
                                label="Descrição"
                                withAsterisk
                                autosize
                                minRows={2}
                                {...form.getInputProps('description')}
                            />
                        </Grid.Col>

                        <Grid.Col xs={12} md={6}>
                            <DateInput
                                valueFormat="DD/MM/YYYY"
                                label="Data de Início"
                                withAsterisk
                                placeholder="Data de Início"
                                {...form.getInputProps('start_date')}
                            />
                        </Grid.Col>

                        <Grid.Col xs={12} md={6}>
                            <DateInput
                                valueFormat="DD/MM/YYYY"
                                label="Data de Fim"
                                withAsterisk
                                placeholder="Data de Fim"
                                {...form.getInputProps('end_date')}
                            />
                        </Grid.Col>

                        <Grid.Col xs={12}>
                            <Button type="submit">Cadastrar Evento</Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}