import React, {useEffect, useState} from "react";
import {Avatar, Button, Container, Grid, Group, MediaQuery, Paper, Text, TextInput, Title} from "@mantine/core";
import DataTable from "react-data-table-component";
import api from "../../../services/api";
import moment from "moment";

export default function ListBannedUsers(){
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(true);
    const [bannedUsers, setBannedUsers] = useState([]);

    function GetBannedUsers(){
        api.get(`/admin/banned-users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            setBannedUsers(response.data.data);
            setLoading(false);
        })
    }

    useEffect(() => {
        GetBannedUsers();
    }, [])


    const columns = [
        {
            name: 'Usuário Twitch',
            selector: row => row.twitch_display_name
        },
        {
            name: 'Motivo do ban',
            selector: row => row.description
        },
        {
            name: 'Data do ban',
            selector: row => moment(row.created_at).format("DD/MM/YYYY"),
        },
        {
            name: 'Duração do ban',
            selector: row => moment(row.ban_end).format("DD/MM/YYYY"),
        },
        // {
        //     name: 'Ação',
        //     center: true,
        //     width: '200px',
        //     cell: row => {
        //         return (
        //             <Group position="apart">
        //                 <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
        //                            style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
        //                            onClick={() => history.push(`/streams/${row.twitch_account_id}`)}>
        //                     <IconEye size={18} color="#8F95B2"/>
        //                 </ThemeIcon>
        //                 <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
        //                            style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
        //                            onClick={() => changeOpen(row.twitch_account_id)}>
        //                     <IconTrash size={18} color="#8F95B2"/>
        //                 </ThemeIcon>
        //             </Group>
        //         )
        //     }
        // },
    ];

    return(
        <Container fluid py="sm">
            <Grid>
                <Grid.Col xs={12}>
                    <Title order={2} c="brand.6">Usuários Banidos</Title>
                </Grid.Col>
                <Grid.Col xs={12}>
                    <Paper shadow="md" radius="md" p="md">
                        <DataTable theme="mosTheme"
                                   columns={columns}
                                   data={bannedUsers}
                                   progressPending={loading}
                        />
                    </Paper>
                </Grid.Col>
            </Grid>
        </Container>
    )
}