import React from "react";
import {Container, FileInput, Grid, NumberInput, Paper, Textarea, TextInput, Title, Button} from "@mantine/core";
import {IconUpload} from "@tabler/icons";
import {useForm} from "@mantine/form";
import api from "../../../services/api";
import NotificationFactory from "../../../Helpers/notificationFactory";


export default function CreateBadge({history}){
    const token = sessionStorage.getItem('user_token');
    const form = useForm({
        initialValues: {
            name: '',
            description:'',
            image:'',
            score: 0
        },
    });

    async function handleSubmit(){
        const formData = new FormData();
        formData.append('name', form.values.name);
        formData.append('description', form.values.description);
        formData.append('image', form.values.image);
        formData.append('score', String(form.values.score));
        try{
            await api.post('/admin/badge', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            NotificationFactory('success', 'Badge cadastrada com sucesso','Sua badge foi cadastrada com sucesso');
            history.push('/badges');
        }catch(e){
            console.log(e.response.data);
            NotificationFactory('error', 'Erro ao criar badge','Não foi possível concluir a ação');
        }
    }

    return(
        <Container fluid>
            <Paper shadow="md" radius="md" p="md">
                <Title order={2} mb="md">Cadastrar Badge</Title>
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Grid>
                        <Grid.Col xs={12} md={4}>
                            <TextInput
                                placeholder="Nome da Badge"
                                label="Nome da Badge"
                                withAsterisk
                                {...form.getInputProps('name')}
                            />
                        </Grid.Col>

                        <Grid.Col xs={12} md={4}>
                            <NumberInput
                                placeholder="Valor do MMR"
                                label="Valor do MMR"
                                withAsterisk
                                hideControls
                                {...form.getInputProps('score')}
                            />
                        </Grid.Col>

                        <Grid.Col xs={12} md={4}>
                            <FileInput
                                placeholder="Selecione uma imagem"
                                label="Imagem da Badge"
                                icon={<IconUpload size={18} />}
                                withAsterisk
                                {...form.getInputProps('image')}
                            />
                        </Grid.Col>

                        <Grid.Col xs={12}>
                            <Textarea
                                placeholder="Descrição"
                                label="Descrição"
                                withAsterisk
                                autosize
                                minRows={2}
                                {...form.getInputProps('description')}
                            />
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <Button type="submit">Cadastrar Badge</Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}