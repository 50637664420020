import React, {useEffect, useState} from "react";
import {
    Container,
    Title,
    Paper,
    Grid,
    TextInput,
    NumberInput,
    Textarea,
    LoadingOverlay, Image
} from "@mantine/core";
import {useForm} from "@mantine/form";
import api from "../../../services/api";

export default function ShowBadge({match}){
    const token = sessionStorage.getItem('user_token');
    const badgeId = match.params.id;
    const [loading, setLoading] = useState(true);
    const form = useForm({
        initialValues: {
            name: '',
            description:'',
            image:'',
            score: 0
        },
    });

    function loadInitialValues() {
        return new Promise(async (resolve) => {
            const response = await api.get(`admin/badge/${badgeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setTimeout(() => resolve({
                name: response.data.name,
                description:response.data.description,
                image: response.data.image,
                score: response.data.score,
            }), 300);
        });
    }

    useEffect(() => {
        loadInitialValues().then((values) => {
            form.setValues(values);
            form.resetDirty(values);
            setLoading(false);
        });
    }, []);

    if(loading){
        return <LoadingOverlay visible={loading}/>
    } else {
        return (
            <Container fluid>
                <Paper shadow="md" radius="md" p="md">
                    <Title order={2} mb="md">Detalhes da Badge</Title>
                    <form>
                        <Grid>
                            <Grid.Col xs={12} md={8}>
                                <Grid>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInput
                                            placeholder="Nome da Badge"
                                            label="Nome da Badge"
                                            withAsterisk
                                            {...form.getInputProps('name')}
                                        />
                                    </Grid.Col>

                                    <Grid.Col xs={12} md={6}>
                                        <NumberInput
                                            placeholder="Valor do MMR"
                                            label="Valor do MMR"
                                            withAsterisk
                                            hideControls
                                            {...form.getInputProps('score')}
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12}>
                                        <Textarea
                                            placeholder="Descrição"
                                            label="Descrição"
                                            withAsterisk
                                            autosize
                                            minRows={2}
                                            {...form.getInputProps('description')}
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Image mx="auto" width="85%" src={`https://api.mos.gg/v1/images/badges/${form.values.image}`}/>
                            </Grid.Col>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        )
    }
}