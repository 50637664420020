export const dark = {
    fontFamily: 'Poppins, sans-serif',
    colors: {
        dark: [
            '#ffffff',
            '#acaebf',
            '#8c8fa3',
            '#666980',
            '#4d4f66',
            '#34354a',
            '#2b2c3d',
            '#0E101C',
            '#0c0d21',
            '#01010a',
        ],
        brand: [
            '#f3f0ff',
            '#e5dbff',
            '#d0bfff',
            '#b197fc',
            '#9775fa',
            '#845ef7',
            '#7950f2',
            '#7048e8',
            '#4A15B9',
            '#5f3dc4' ],
        accent: [
            '#deffde',
            '#afffaf',
            '#7dff7e',
            '#4bff4c',
            '#1aff1a',
            '#00e600',
            '#00b300',
            '#008000',
            '#004e00',
            '#001c00',
        ]
    },
    primaryColor: 'brand',
    colorScheme: 'dark',
    black:'#0E101C',

    headings: {
        fontFamily: 'Poppins, sans-serif',
        sizes: {
            h1: { fontSize: 36, fontWeight: 500 },
            h4: {fontSize: 16}
        }
    },
    components: {
        Paper: {
            styles: {
                root: {backgroundColor: '#16192A'},
            },
        }
    }
}