import React, { useState} from "react";
import {IconLock, IconLogout} from "tabler-icons";
import {AppShell, Navbar,List, Group, Drawer, MediaQuery, Burger} from "@mantine/core";
import Logo from "../../../Assets/logo.png";



export default function Base({history, children}) {
    const [opened, setOpened] = useState(false);
    const [asideOpen, setAsideOpen] = useState(false);

    function leaveApp(){
        sessionStorage.clear();
        history.push('/');
    }

    const listAdminItems = [
        {
            id:1,
            name: 'Início',
            icon:  <IconLock size={20}/>,
            link:'/inicio'
        },
        {
            id:2,
            name: 'Usuários',
            icon:  <IconLock size={20}/>,
            link:'/usuarios'
        },
        {
            id:3,
            name: 'Streams',
            icon:  <IconLock size={20}/>,
            link:'/streams'
        },
        {
            id:4,
            name: 'Jogos',
            icon:  <IconLock size={20}/>,
            link:'/games'
        },
        {
            id:5,
            name: 'Badges',
            icon:  <IconLock size={20}/>,
            link:'/badges'
        },
        {
            id:6,
            name: 'EAD',
            icon:  <IconLock size={20}/>,
            link:'/ead'
        },
        {
            id:7,
            name: 'Bingo',
            icon:  <IconLock size={20}/>,
            link:'/bingo'
        },
        {
            id:8,
            name: 'Usuários Banidos',
            icon:  <IconLock size={20}/>,
            link:'/usuarios-banidos'
        },
        {
            id:8,
            name: 'Eventos',
            icon:  <IconLock size={20}/>,
            link:'/eventos'
        },
    ]

    return(
        <AppShell
            padding="md"
            navbar={
                <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 250 }}>
                    <Navbar.Section>
                        <img src={Logo} style={{marginTop:16}}/>
                    </Navbar.Section>
                    <Navbar.Section grow mt="md">
                        <List
                            spacing={2}
                            size="sm"
                            center
                        >
                            {listAdminItems.map( item => {
                                return(
                                    <List.Item
                                        key={item.id}
                                        icon={item.icon}
                                        sx={(theme) => ({
                                            fontFamily:'Poppins',
                                            color:'#8F95B2',
                                            fontWeight:500,
                                            fontSize:13,
                                            padding:'12px 14px',
                                            borderRadius:10,
                                            '&:hover': {
                                                backgroundColor: theme.colors.brand[6],
                                                color: '#ffffff',
                                                cursor: 'pointer'
                                            },
                                            '&:active': {
                                                backgroundColor: theme.colors.brand[6],
                                                color: '#ffffff',
                                                cursor: 'pointer'
                                            }
                                        })}
                                        onClick={() => history.push(item.link)}
                                    >
                                        {item.name}
                                    </List.Item>
                                )
                            })}
                        </List>
                    </Navbar.Section>
                    <Navbar.Section>
                        <Group position="apart" spacing={0}>
                            <IconLogout size={20} color="#ffffff" style={{cursor: 'pointer'}} onClick={() => leaveApp()}/>
                        </Group>
                    </Navbar.Section>
                </Navbar>}
            aside={
                <Drawer
                    opened={asideOpen}
                    onClose={() => setAsideOpen(!asideOpen)}
                    padding="xl"
                    size="md"
                >
                    <List
                        spacing={2}
                        size="sm"
                        center
                    >
                        {listAdminItems.map( item => {
                            return(
                                <List.Item
                                    key={item.id}
                                    icon={item.icon}
                                    sx={(theme) => ({
                                        fontFamily:'Lato',
                                        color:'#8F95B2',
                                        fontWeight:700,
                                        fontSize:14,
                                        padding:'12px 15px',
                                        borderRadius:10,
                                        '&:hover': {
                                            backgroundColor: theme.colors.brand[6],
                                            color: '#ffffff',
                                            cursor: 'pointer'
                                        },
                                        '&:active': {
                                            backgroundColor: theme.colors.brand[6],
                                            color: '#ffffff',
                                            cursor: 'pointer'
                                        }
                                    })}
                                    onClick={() => history.push(item.link)}
                                >
                                    {item.name}
                                </List.Item>
                            )
                        })}
                    </List>
                </Drawer>
            }

            styles={(theme) => ({
                main: { backgroundColor: '#0E101C' },
            })}
        >
                <MediaQuery largerThan="md" styles={{display:'none'}}>
                    <Burger
                        opened={asideOpen}
                        onClick={() => setAsideOpen(!asideOpen)}
                        size="sm"
                        color="#898EB1"
                        mr="xl"
                    />
                </MediaQuery>
            {children}
        </AppShell>
    );



}