import React from "react";
import {Text, Tooltip} from "@mantine/core";


export default function ParseBadge({name, imageId, description}){
    const url = 'https://api.mos.gg/v1/images/badges/' + imageId;


    return(
        <Tooltip label={description} color="dark" withArrow>
            <center>
                <img src={url} style={{ height:120 }} alt={description}/>
                <Text size="sm" transform="uppercase">{name}</Text>
            </center>

        </Tooltip>
    )
}