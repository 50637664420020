import React, {useEffect, useState} from "react";
import {Grid, Paper, Group, ThemeIcon, Container, Title, Badge, LoadingOverlay, Button} from "@mantine/core";
import api from "../../../services/api";
import {IconEye} from "tabler-icons";
import moment from "moment";
import DataTable from "react-data-table-component";
import {IconPencil} from "@tabler/icons";



export default function ListBadges({history}) {
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(true);
    const [badges, setBadges] = useState([]);


    function getBadges(){
        api.get('/admin/badge/index', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            setBadges(response.data.data);
            setLoading(false);
        })

    }

    useEffect(() => {
        getBadges();
    }, []);


    const columns = [
        {
            name: 'Nome',
            selector: row => row.name,
        },
        {
            name: 'Descrição',
            selector: row => row.description,
        },
        {
            name: 'MMR',
            selector: row => row.score,
        },
        {
            name: 'Visualização',
            selector: row => row.active,
            center: true,
            cell: row => {
                return row.active? (
                    <Badge variant="outline" color='green' fullWidth>
                        Ativa
                    </Badge>
                ) : (
                    <Badge variant="outline" color='red' fullWidth>
                        Inativa
                    </Badge>
                )
            }
        },
        {
            name: 'Cadastro',
            selector: row => moment(row.created_at).format("DD/MM/YYYY"),
        },
        {
            name: 'Ação',
            center: true,
            cell: row => {
                return (
                    <Group position="apart">
                        <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
                                   style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
                                   onClick={() => history.push(`/badges/${row.id}`)}>
                            <IconEye size={18} color="#8F95B2"/>
                        </ThemeIcon>
                        <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
                                   style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
                                   onClick={() => history.push(`/badges/editar/${row.id}`)}>
                            <IconPencil size={18} color="#8F95B2"/>
                        </ThemeIcon>
                    </Group>
                )
            }
        },
    ];

    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Group position="apart">
                                <Title>Badges MOS.GG</Title>
                                <Button onClick={() => history.push('/badges/cadastrar')} >Adicionar badge</Button>
                            </Group>

                            <DataTable theme="mosTheme" columns={columns}
                                       data={badges}
                                       pagination
                                       paginationPerPage={20}
                                       progressPending={loading}
                            />
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Container>
        )
    }
}