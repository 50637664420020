import React, {useState} from "react";
import "./style.scss";
import api from "../../services/api";
import {Container, Grid, Paper, Button, PasswordInput, TextInput, Title, LoadingOverlay} from "@mantine/core";


export default function Login({ history }){
    const [loading, setLoading] = useState(false);

    const [ user, setUser ] = useState({
        email: '',
        password: ''
    });

    // Logar
    const handleSubmit = (e) => {
        e.preventDefault();
        api.post('/authenticate', user).then( response => {
            sessionStorage.setItem('authorization', response.data.token);
            sessionStorage.setItem('role', response.data.user.role);
        }).then (() => {
            history.push('/inicio');
        })
	}

    if(loading) return <LoadingOverlay visible={loading} overlayBlur={2} />
    
    return(
        <Container fluid style={{height:'101vh', backgroundColor:'#0E101C'}}>
            <Grid justify="center" align="center" style={{height:'inherit'}}>
                <Grid.Col xs={12} md={3}>
                    <Paper shadow="xs" p="md" r="lg" style={{minHeight:400}}>
                        <Title order={3} c="brand.6" mb="md" ta="center">Seja bem vindo a MOS.GG</Title>
                       <Title order={3} c="brand.6" mb="md" ta="center">Faça Login</Title>
                        <form onSubmit={ e => handleSubmit(e)}>
                            <TextInput
                                placeholder="E-mail"
                                label="E-mail"
                                withAsterisk
                                mb="sm"
                                value={user.email} onChange={e => setUser({ ...user, email: e.target.value })}
                            />
                            <PasswordInput
                                placeholder="Senha"
                                label="Senha"
                                withAsterisk
                                value={user.password} onChange={e => setUser({ ...user, password: e.target.value })}
                            />
                            <Button fullWidth mt="lg" type="submit">Fazer login</Button>
                        </form>
                    </Paper>
                </Grid.Col>
            </Grid>
        </Container>
    )
}