
import  { createTheme } from 'react-data-table-component';

const mosTheme = createTheme('mosTheme', {
    text: {
        primary: '#898EB1',
        secondary: '#898EB1',
    },
    background: {
        default: '#16192A',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#41466D',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

export default mosTheme;