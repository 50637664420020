import React, {useEffect, useState} from 'react';
import {
    Container,
    Title,
    Paper,
    LoadingOverlay,
    Grid,
    TextInput,
    NumberInput,
    FileInput,
    Textarea, Button
} from "@mantine/core";
import {useForm} from "@mantine/form";
import api from "../../../services/api";
import {IconUpload} from "@tabler/icons";
import NotificationFactory from "../../../Helpers/notificationFactory";

export default function EditBadge({match, history}) {
    const token = sessionStorage.getItem('user_token');
    const badgeId = match.params.id;
    const [loading, setLoading] = useState(true);
    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            image: '',
            old_image:'',
            score: 0
        },
    });

    function loadInitialValues() {
        return new Promise(async (resolve) => {
            const response = await api.get(`admin/badge/${badgeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setTimeout(() => resolve({
                name: response.data.name,
                description: response.data.description,
                image: response.data.image,
                old_image: response.data.image,
                score: response.data.score,
            }), 300);
        });
    }

    useEffect(() => {
        loadInitialValues().then((values) => {
            form.setValues(values);
            form.resetDirty(values);
            setLoading(false);
        });
    }, []);

    async function handleSubmit(){
        const formData = new FormData();
        formData.append('name', form.values.name);
        formData.append('description', form.values.description);
        formData.append('image', form.values.image);
        formData.append('score', String(form.values.score));
        formData.append('old_image', form.values.old_image);
        try{
            await api.put(`/admin/badge/${badgeId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            NotificationFactory('success', 'Badge editada com sucesso','Sua badge foi editada com sucesso');
        }catch(e){
            console.log(e.response.data);
            NotificationFactory('error', 'Erro ao editar badge','Não foi possível concluir a ação');
        }
    }

    if (loading) {
        return <LoadingOverlay visible={loading}/>
    } else {
        return (
            <Container fluid>
                <Paper shadow="md" radius="md" p="md">
                    <Title order={2} mb="md">Editar Badge</Title>
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <Grid>
                            <Grid.Col xs={12} md={4}>
                                <TextInput
                                    placeholder="Nome da Badge"
                                    label="Nome da Badge"
                                    withAsterisk
                                    {...form.getInputProps('name')}
                                />
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <NumberInput
                                    placeholder="Valor do MMR"
                                    label="Valor do MMR"
                                    withAsterisk
                                    hideControls
                                    {...form.getInputProps('score')}
                                />
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <FileInput
                                    placeholder="Selecione uma imagem"
                                    label="Imagem da Badge"
                                    icon={<IconUpload size={18} />}
                                    withAsterisk
                                    {...form.getInputProps('image')}
                                />
                            </Grid.Col>

                            <Grid.Col xs={12}>
                                <Textarea
                                    placeholder="Descrição"
                                    label="Descrição"
                                    withAsterisk
                                    autosize
                                    minRows={2}
                                    {...form.getInputProps('description')}
                                />
                            </Grid.Col>
                            <Grid.Col xs={12}>
                                <Button type="submit">Editar Badge</Button>
                            </Grid.Col>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        )
    }
}