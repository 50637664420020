import React, { useState} from "react";
import {
    Button,
    Grid,
    Paper,
    Container,
    Title,
    TextInput,
    LoadingOverlay
} from '@mantine/core'
import api from "../../../services/api";


export default function NewStream({history}) {
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(false);
    const [stream, setStream] = useState({});


    async function CreateStream(){
         await api.post(`/admin/streams/create`, stream, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })

    }

    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>

                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Title order={2} mb="md">Nova Stream Manual</Title>
                            <Grid>
                                <Grid.Col xs={12} md={6}>
                                    <TextInput label="Título" placeholder="Título da Live" onChange={e => setStream({...stream, title: e.target.value})}/>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <TextInput label="Nome do Jogo" placeholder="Nome do Jogo" onChange={e => setStream({...stream, game_name: e.target.value})}/>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <TextInput label="ID do Jogo" placeholder="ID do Jogo" onChange={e => setStream({...stream, game_id: e.target.value})}/>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <TextInput label="ID do Usuário" placeholder="ID do Usuário" onChange={e => setStream({...stream, userID: e.target.value})}/>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <TextInput label="URL da Thumbnail" placeholder="URL da Thumbnail"
                                               description="https://static-cdn.jtvnw.net/previews-ttv/live_user_nomedeusuario-{width}x{height}.jpg"
                                               onChange={e => setStream({...stream, thumbnail_url: e.target.value})}/>
                                </Grid.Col>

                                <Grid.Col xs={12}>
                                    <Button onClick={() => CreateStream()}>Cadastrar Stream Manual</Button>
                                </Grid.Col>
                            </Grid>
                        </Paper>
                    </Grid.Col>
                </Grid>

            </Container>
        )
    }
}