import React from "react";
import './App.css';
import Routes from "./routes";
import {MantineProvider} from "@mantine/core";
import { Notifications } from '@mantine/notifications';
import {dark} from "./Theme/dark";

function App() {
  return (
      <MantineProvider theme={dark}>
          <Notifications />
         <Routes/>
      </MantineProvider>
  );
}

export default App;
