import React, {useEffect, useState} from "react";
import {Grid, Paper, Group, ThemeIcon, Container, Button, Title, Badge, LoadingOverlay} from "@mantine/core";
import api from "../../../services/api";
import {IconEye} from "tabler-icons";
import moment from "moment";
import DataTable from "react-data-table-component";



export default function ListEad({history}) {
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(true);
    let [videos, setVideos] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0)

   function getVideos(){
        api.get('/admin/video/', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            setVideos(response.data);
            setLoading(false);
        })

   }

    useEffect(() => {
        getVideos();
    }, []);


    const columns = [
        {
            name: 'Título',
            selector: row => row.title,
            minWidth: '400px',
        },
        {
            name: 'Categoria',
            selector: row => row.category_name,
            minWidth: '280px',
        },
        {
            name: 'URL',
            selector: row => row.video_url,
            cell: row => {
                return(
                    <Button component='a' href={row.video_url} target="_blank">Ver URL</Button>
                )
            }
        },
        {
            name: 'Visualização',
            selector: row => row.active,
            center: true,
            cell: row => {
                return row.active? (
                    <Badge variant="outline" color='green' fullWidth>
                        Ativa
                    </Badge>
                ) : (
                    <Badge variant="outline" color='red' fullWidth>
                       Inativa
                    </Badge>
                )
            }
        },
        {
            name: 'Spotlight',
            selector: row => row.spotlight,
            center: true,
            cell: row => {
                return row.spotlight? (
                    <Badge variant="outline" color='green' fullWidth>
                        Sim
                    </Badge>
                ) : (
                    <Badge variant="outline" color='red' fullWidth>
                        Não
                    </Badge>
                )
            }
        },
        {
            name: 'Cadastro',
            selector: row => moment(row.created_at).format("DD/MM/YYYY"),
            width: '120px',
        },
        {
            name: 'Ação',
            center: true,
            width: '180px',
            cell: row => {
                return (
                    <Group position="apart">
                        <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
                                   style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
                                   onClick={() => history.push(`/ead/${row.video_id}`)}>
                            <IconEye size={18} color="#8F95B2"/>
                        </ThemeIcon>
                    </Group>
                )
            }
        },
    ];

    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Title>Vídeos EAD</Title>
                            <DataTable theme="mosTheme" columns={columns}
                                       data={videos}
                                       pagination
                                       paginationPerPage={20}
                                       progressPending={loading}
                            />
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Container>
        )
    }
}