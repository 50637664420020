import React, {useEffect, useState} from "react";
import {Grid, Paper, Tabs, LoadingOverlay, Title, Text, Group, ThemeIcon, Modal} from "@mantine/core";
import DataTable from 'react-data-table-component';
import api from "../../../services/api";
import {IconChevronDown, IconEye} from "tabler-icons";
import LogoBingo from "../../../Assets/logoBingo.png";
import "./style.scss";
import moment from "moment";
import Logo from "../../../Assets/spinner.png";
import mosTheme from "../../../Theme/table";


export default function ListCartelas(){
    let token = localStorage.getItem('authorization');
    const [sentData, setSentData] = useState([]);
    const [sentPage, setSentPage] = useState(1);
    const [sentCount, setSentCount] = useState(1);
    const [totalData, setTotalData] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [count, setCount] = useState({});
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [cartela, setCartela] = useState([]);

    async function GetSentData(page){
       const response = await api.get(`/admin/cartela/sent/index/${page}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
       let array = []
        response.data.data.map( (item, index) => {
            array.push({twitch_name: item.twitch_name, created_at: item.created_at, bingo_key: item.bingo_key.flat()})
        })
       setSentCount(Number(response.data.total))
       setSentData(array);
       setSentPage(response.data.page)
    }

    async function GetTotalData(page){
        const response = await api.get(`/admin/cartela/info/${page}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        let array = []
        response.data.data.map( item => {
            let newNumber = item.bingo_key.replace(/\d{2}(?!$)/g, "$& ");
            item.bingo_key = newNumber;
            array.push(item);
        })

        setTotalCount(Number(response.data.total))
        setTotalData(array);
        setTotalPage(response.data.page)
    }



    async function GetCount(){
        const response = await api.get('/admin/cartela/count', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        setCount(response.data);
    }

    useEffect(() => {
        GetSentData(sentPage);
        GetTotalData(totalPage);
        GetCount();
        setLoading(false);

    }, [])

    const totalColumns = [
        {
            name: 'Usuário Twitch',
            selector: row => row.twitch_display_name,
            sortable: true,
        },
        {
            name: 'Números da Cartela',
            selector: row => row.bingo_key,
            sortable: true,
        },
        {
            name: 'Comprado em:',
            selector: row => moment(row.updated_at).format("DD/MM/YYYY - hh:mm:ss"),
            sortable: true,
        }
    ];

    const sentColumns = [
        {
            name: 'Usuário Twitch',
            selector: row => row.twitch_name,
            sortable: true,
            width: '350px',
        },
        {
            name: 'Números da Cartela',
            selector: row => row.bingo_key.join(' - '),
            sortable: true,
            minWidth: '400px',
        },
        {
            name: 'Enviado em:',
            selector: row => moment(row.created_at).format("DD/MM/YYYY - hh:mm:ss"),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Ação',
            center: true,
            width: '200px',
            cell: row => {
                return(
                    <Group position="apart">
                        <ThemeIcon variant="light" size="lg"  radius="xl" color="#ffffff" style={{cursor:'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
                                   onClick={() => {
                                       setCartela(row.bingo_key);
                                       setOpenModal(true);
                                   }}>
                            <IconEye size={18} color="#8F95B2"/>
                        </ThemeIcon>
                    </Group>
                )
            }
        },
    ];

    const handleSentPageChange = page => {
        GetSentData(page);
    };

    const handleTotalPageChange = page => {
        GetTotalData(page);
    };

    const bingo = [ 'B', 'I', 'N', 'G', 'O']

    if(loading){
        return(
            <LoadingOverlay visible={loading} overlayBlur={2} />
            )
    } else {
        return(
            <Grid>

                <Modal
                    opened={openModal}
                    onClose={() => setOpenModal(false)}
                    title="Conferir cartela"
                >
                    <Grid>
                        <Grid.Col xs={12}>
                            <Paper  style={{backgroundColor:'#16192A', borderRadius:10, padding:10}}>
                                <Grid columns={30} justify="space-between" style={{marginBottom:8}}>
                                    {bingo.map( (item, index) => {
                                        return(
                                            <Grid.Col span={6} key={index}>
                                                <Paper style={{ borderRadius:4, backgroundColor: '#4A15B9',height:55, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                                    <Text size="xl" weight={600}>{item}</Text>
                                                </Paper>
                                            </Grid.Col>
                                        )
                                    })}
                                </Grid>

                                <Grid columns={30}>
                                    {cartela.map( (item, index) => {
                                        return(
                                            <Grid.Col span={6} >
                                                <Paper style={{ backgroundColor:'#23273B' , cursor: 'pointer', borderRadius:4, height:55, display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                                    {item === 'XX' ? <img src={Logo} style={{width:'60%'}}/> : item}
                                                </Paper>
                                            </Grid.Col>
                                        )
                                    })}
                                </Grid>
                            </Paper>
                        </Grid.Col>
                    </Grid>

                </Modal>

                <Grid.Col span={6}>
                    <Paper shadow="md" radius="md" p="md">
                        <center>
                            <img src={LogoBingo} style={{maxWidth:400}}/>
                        </center>
                    </Paper>
                </Grid.Col>

                <Grid.Col span={3}>
                    <Paper shadow="md" radius="md" p="md" style={{height:200}}>
                        <Text size="md">Cartelas vendidas</Text>
                        <Title order={1} style={{fontSize:'4em'}}>{count.cartelas_vendidas}</Title>
                    </Paper>
                </Grid.Col>

                <Grid.Col span={3}>
                    <Paper shadow="md" radius="md" p="md" style={{height:200}}>
                        <Text size="md">Cartelas livres</Text>
                        <Title order={1} style={{fontSize:'4em'}}>{count.cartelas_livres}</Title>
                    </Paper>
                </Grid.Col>


                <Grid.Col span={12}>
                    <Paper shadow="md" radius="md" p="md">
                        <Tabs defaultValue="enviadas" color="violet">
                            <Tabs.List>
                                <Tabs.Tab value="enviadas" icon={<IconChevronDown size={20} color="#8A8EA4" stroke={2} strokeLinejoin="miter" style={{marginRight:10}}/>}>Cartelas enviadas</Tabs.Tab>
                                <Tabs.Tab value="totais" icon={<IconChevronDown size={20} color="#8A8EA4" stroke={2} strokeLinejoin="miter" style={{marginRight:10}}/>}>Cartelas totais</Tabs.Tab>
                            </Tabs.List>

                            <Tabs.Panel value="enviadas" pt="xs">
                                    <DataTable theme="mosTheme" columns={sentColumns}
                                               data={sentData}
                                               pagination
                                               paginationServer
                                               paginationPerPage={20}
                                               progressPending={loading}
                                               paginationDefaultPage={sentPage}
                                               paginationTotalRows={sentCount}
                                               onChangePage={handleSentPageChange}
                                               paginationComponentOptions={{
                                                   noRowsPerPage:true,
                                                   rangeSeparatorText: 'de',
                                               }}
                                    />
                            </Tabs.Panel>

                            <Tabs.Panel value="totais" pt="xs">
                                <DataTable theme="mosTheme" columns={totalColumns}
                                           pagination
                                           paginationServer
                                           paginationPerPage={20}
                                           progressPending={loading}
                                           data={totalData}
                                           paginationTotalRows={totalCount}
                                           paginationDefaultPage={totalPage}
                                           onChangePage={handleTotalPageChange}
                                           paginationComponentOptions={{
                                               noRowsPerPage:true,
                                               rangeSeparatorText: 'de',
                                           }}
                                />
                            </Tabs.Panel>
                        </Tabs>
                    </Paper>
                </Grid.Col>
            </Grid>
        )
    }
}