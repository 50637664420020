import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {Container, Grid, LoadingOverlay, Paper, Stack, Text, Title} from "@mantine/core";

const options = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export default function Home() {
  const [labelUsersCount, setLabelUsersCount] = useState([]);
  const [dataUsersCount, setDataUsersCount] = useState([]);
  const [usersCount, setUsersCount] = useState({});
  const [loader, setLoader] = useState(true);

  const data = {
    labels: labelUsersCount,
    datasets: [
      {
        label: "N° de usuários cadastrados",
        data: dataUsersCount,
        fill: false,
        backgroundColor: "#A07BEB",
        borderColor: "#A07BEB",
      },
    ],
  };

  async function getCountUsersGraph() {
    try {
      const response = await api.get("admin/users/count/graph");
      response.data.slice(response.data.length - 15, response.data.length).map(object => {
        setLabelUsersCount(prevState => [...prevState, moment(object.timestamp).format('DD/MM')]);
        setDataUsersCount(prevState => [...prevState, object.count]);
      });
      setLoader(false);
    } catch (error) {
      console.log(error.response);
    }
  }

  async function getCountUsers() {
      try {
        const response = await api.get("/admin/statistics");
        setUsersCount(response.data);
        setLoader(false);
      } catch (error) {
        console.log(error.response);
        setLoader(false);
      }
  }

  useEffect(() => {
    getCountUsersGraph();
    getCountUsers();
  }, []);

  if (loader) {
      return <LoadingOverlay visible={loader} overlayBlur={2} />
  } else {
      return (
          <Container fluid>
            <Grid>
              <Grid.Col xs={12} md={3}>
                <Paper shadow="md" radius="md" p="md">
                  <Stack spacing="xs">
                    <Text>Usuários</Text>
                    <Title order={1}>{usersCount.users}</Title>
                  </Stack>
                </Paper>
              </Grid.Col>

              <Grid.Col xs={12} md={3}>
                <Paper shadow="md" radius="md" p="md">
                  <Stack spacing="xs">
                  <Text>Raids</Text>
                  <Title order={1}>{usersCount.raids}</Title>
                  </Stack>
                </Paper>
              </Grid.Col>

              <Grid.Col xs={12} md={3}>
                <Paper shadow="md" radius="md" p="md">
                  <Stack spacing="xs">
                  <Text>Follows</Text>
                  <Title order={1}>{usersCount.follows}</Title>
                  </Stack>
                </Paper>
              </Grid.Col>

              <Grid.Col xs={12} md={3}>
                <Paper shadow="md" radius="md" p="md">
                  <Stack spacing="xs">
                  <Text>Tickets comprados</Text>
                  <Title order={1}>{usersCount.tickets}</Title>
                  </Stack>
                </Paper>
              </Grid.Col>

              <Grid.Col xs={12} md={8}>
                <Paper shadow="md" radius="md" p="md">
                  <Line data={data} options={options} height={152} />
                </Paper>
              </Grid.Col>

              <Grid.Col xs={12} md={4}>
                <Paper shadow="md" radius="md" p="md" style={{marginBottom:16}}>
                  <Stack spacing="xs">
                    <Text>Twitch desconectada</Text>
                    <Title order={1}>{usersCount.twitch_not_connected}</Title>
                  </Stack>
                </Paper>

                <Paper shadow="md" radius="md" p="md" style={{marginBottom:16}}>
                  <Stack spacing="xs">
                  <Text>Missão diária inativa</Text>
                  <Title order={1}>{usersCount.never_daily_achievement}</Title>
                  </Stack>
                </Paper>
              </Grid.Col>

            </Grid>
          </Container>
      );
  }

}
