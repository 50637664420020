import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./store";
import React from "react";
import {isAuthenticated} from "./auth";

import baseDashboard from "./Pages/HOC/baseDashboard";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Users from "./Pages/Users";
import UsersDetails from "./Pages/userDetails";
import ListCartelas from "./Pages/StreamerBingo/ListCartelas";
import ListEad from "./Pages/EAD/ListEad";
import EadDetails from "./Pages/EAD/DetailsEad";
import ListBadges from "./Pages/Badges/ListBadges";
import ListStreams from "./Pages/Streams/listStreams";
import ShowStream from "./Pages/Streams/showStream";
import NewStream from "./Pages/Streams/newStream";
import ListGames from "./Pages/Games/ListGames";
import CreateBadge from "./Pages/Badges/CreateBadge";
import ShowBadge from "./Pages/Badges/ShowBadge";
import EditBadge from "./Pages/Badges/EditBadge";
import CreateVideo from "./Pages/EAD/PostEad";
import ListBannedUsers from "./Pages/BannedUsers/ListBannedUsers";
import CreateEvent from "./Pages/Events/createEvent";
import ListEvents from "./Pages/Events/listEvents";
import EditEvent from "./Pages/Events/editEvent";
import ShowEvent from "./Pages/Events/showEvent";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route 
        {...rest} 
        render={props => isAuthenticated(['Admin']) ? 
            ( <Component {...props} />) 
        : 
            ( <Redirect to={{ pathname: "/", state: {from: props.location}}}/>)
        } 
    />
);

const Routes = () => (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={Login}/>
                <PrivateRoute path="/inicio" exact component={baseDashboard(Home)}/>
                <PrivateRoute path="/usuarios" exact component={baseDashboard(Users)}/>
                <PrivateRoute path="/bingo" exact component={baseDashboard(ListCartelas)}/>
                <PrivateRoute path="/ead" exact component={baseDashboard(ListEad)}/>
                <PrivateRoute path="/ead/cadastrar" exact component={baseDashboard(CreateVideo)}/>
                <PrivateRoute path="/badges" exact component={baseDashboard(ListBadges)}/>
                <PrivateRoute path="/badges/cadastrar" exact component={baseDashboard(CreateBadge)}/>
                <PrivateRoute path="/badges/:id" exact component={baseDashboard(ShowBadge)}/>
                <PrivateRoute path="/badges/editar/:id" exact component={baseDashboard(EditBadge)}/>
                <PrivateRoute path="/ead/:id" exact component={baseDashboard(EadDetails)}/>
                <PrivateRoute path="/usuarios/:id" exact component={baseDashboard(UsersDetails)}/>
                <PrivateRoute path="/streams" exact component={baseDashboard(ListStreams)}/>
                <PrivateRoute path="/streams/add" exact component={baseDashboard(NewStream)}/>
                <PrivateRoute path="/streams/:id" exact component={baseDashboard(ShowStream)}/>
                <PrivateRoute path="/games" exact component={baseDashboard(ListGames)}/>
                <PrivateRoute path="/usuarios-banidos" exact component={baseDashboard(ListBannedUsers)}/>

                <PrivateRoute path="/eventos" exact component={baseDashboard(ListEvents)}/>
                <PrivateRoute path="/eventos/cadastrar" exact component={baseDashboard(CreateEvent)}/>
                <PrivateRoute path="/eventos/:id" exact component={baseDashboard(ShowEvent)}/>
                <PrivateRoute path="/eventos/editar/:id" exact component={baseDashboard(EditEvent)}/>

            </Switch>
        </BrowserRouter>
    </Provider>
);

export default Routes;