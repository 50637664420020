import React, {useEffect, useState} from "react";
import {
    Grid,
    Paper,
    Container,
    Title,
    TextInput,
    LoadingOverlay
} from '@mantine/core'
import api from "../../../services/api";


export default function EadDetails({match, history}) {
    let token = localStorage.getItem('authorization');
    const [video, setVideo] = useState({})
    const [loading, setLoading] = useState(true);

    function getVideo() {
        api.get(`/ead/video/${match.params.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
                setVideo(response.data);
            }
        )
    }


    useEffect(() => {
        getVideo()
        setLoading(false);

    }, []);


    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>

                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Title order={2} style={{marginBottom:32}}>Detalhes do vídeo</Title>
                            <Grid>
                                <Grid.Col xs={12} md={5}>
                                    <TextInput style={{marginBottom: 16}} label="Título" value={video.title} readOnly/>
                                    <TextInput style={{marginBottom: 16}} label="Categoria" value={video.category} readOnly/>
                                    <TextInput style={{marginBottom: 16}} label="URL" value={video.video} readOnly/>
                                </Grid.Col>
                            </Grid>
                        </Paper>
                    </Grid.Col>
                </Grid>

            </Container>
        )
    }
}