import React, {useEffect, useState} from "react";
import {Grid, Paper, Group, ThemeIcon, Container, Button, MediaQuery, TextInput, Title, Loader, Modal, Text, Stack, LoadingOverlay} from "@mantine/core";
import api from "../../../services/api";
import {IconEye, IconTrash} from "tabler-icons";
import moment from "moment";
import DataTable from "react-data-table-component";
import { notifications } from '@mantine/notifications';


export default function ListStreams({history}) {
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(true);
    let [streams, setStreams] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [twitchId, setTwitchId] = useState('');
    const [modalAdd, setModalAdd] = useState(false);
    const [modalVerifyStream, setmodalVerifyStream] = useState(false);
    const [userId, setUserId] = useState('');
    let [offStreams, setOffStreams] = useState('');
    let [loadingStreamsEraser, setloadingStreamsEraser] = useState(false);

    function getStreams(page) {
        api.get(`admin/streams/all?page=${page}&order=asc`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setStreams(response.data.data);
            setPage(response.data.page);
            setCount(response.data.total)
            setLoading(false);
        })
    }

    function getOffStreamCounts() {
        api.get(`/twitch/check/offline-streams`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setOffStreams(response.data.data.streamOfflineCount)
        })
    }

    useEffect(() => {
        getStreams(1);
    }, []);


    function SearchItem() {
        setLoading(true);
        api
            .get(`admin/streams/search?term=${search}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                setStreams(response.data.data);
                setPage(response.data.page);
                setCount(response.data.total)
                setLoading(false);
            });
    }

    const handlePageChange = page => {
        getStreams(page);
    };

    function deleteStream(){
        api.delete(`/admin/streams/delete/${twitchId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            getStreams(page);
            setOpen(!open);
        })
    }

    function addAutoStream(){
        api.post(`/admin/stream/add`, {
            userID: userId
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            getStreams(page)
            setModalAdd(!modalAdd);
        })
    }

    function changeOpen(id){
        setTwitchId(id);
        setOpen(!open);
    }

    function showNotification(title, description){
        notifications.show({
            title: title,
            message: description,
          })
    }

    async function checkOfflineStreams(){
        try{
            const response = await api.post(`/twitch/check/offline-streams`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            })
            //console.log(response.data.streamOfflineCount);
            setOffStreams(response.data.streamOfflineCount)
        } catch(e){
            showNotification("Ocorreu um erro.", "Não foi possível carregar a quantidade de streams offline.")
        }
    }

    async function eraseOffStreams(){
        setloadingStreamsEraser(true);
        try{
            await api.post(`/twitch/check/offline-streams?deleteStream=true`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            })
            showNotification('Sucesso!', "As lives foram apagadas com sucesso.")

        } catch(e){
            showNotification("Ocorreu um erro.", "Não foi possível apagar as lives offline.")
        } finally{
            setloadingStreamsEraser(false);
        }
    }

    function setModalState(){
        setmodalVerifyStream(!modalVerifyStream)
        checkOfflineStreams();
    }


    const columns = [
        {
            name: 'Username',
            width:'300px',
            selector: row => row.twitch_display_name,
            sortable: true,
        },
        {
            name: 'Título',
            width:'500px',
            selector: row => row.title,
            sortable: true,
        },
        {
            name: 'Jogo',
            selector: row => row.game,
            sortable: true,
        },
        {
            name: 'Data da Live',
            selector: row => moment(row.created_at).format("DD/MM/YYYY"),
            sortable: true,
            width: '200px',
        },
        {
            name: 'Ação',
            center: true,
            width: '200px',
            cell: row => {
                return (
                    <Group position="apart">
                        <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
                                   style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
                                   onClick={() => history.push(`/streams/${row.twitch_account_id}`)}>
                            <IconEye size={18} color="#8F95B2"/>
                        </ThemeIcon>
                        <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
                                   style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
                                   onClick={() => changeOpen(row.twitch_account_id)}>
                            <IconTrash size={18} color="#8F95B2"/>
                        </ThemeIcon>
                    </Group>
                )
            }
        },
    ];

    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>
                <Modal opened={open} onClose={() => setOpen(!open)}>
                   <Title order={3} ta="center"> Você tem certeza que quer deletar essa stream?</Title>
                    <Text size="sm" ta="center" mt="md">Essa ação não poderá ser desfeita. Você gostaria de deletar a stream?</Text>
                    <Group position="apart" mt="md">
                        <Button onClick={() => setOpen(!open)} style={{width:'45%'}}>Cancelar</Button>
                        <Button onClick={() => deleteStream()} style={{width:'45%'}}>Deletar Stream</Button>
                    </Group>
                </Modal>

                <Modal opened={modalAdd} onClose={() => setModalAdd(!modalAdd)}>
                    <Title order={3} ta="center"> Adicionar Stream Automaticamente?</Title>
                    <Text size="sm" ta="center" my="md">Para adicionar uma nova live automaticamente, o streamer precisa estar online no momento da adição. </Text>

                    <TextInput
                        placeholder="ID do Usuário"
                        label="ID do Usuário"
                        onChange={e => setUserId(e.target.value)}
                    />

                    <Group position="apart" mt="md">
                        <Button onClick={() => setModalAdd(!modalAdd)} style={{width:'45%'}}>Cancelar</Button>
                        <Button onClick={() => addAutoStream()} style={{width:'45%'}}>Adicionar Stream</Button>
                    </Group>
                </Modal>

                <Modal opened={modalVerifyStream} onClose={() => setmodalVerifyStream(!modalVerifyStream)}>
                    <Title order={3} ta="center"> Verificar streams offline</Title>
                    {offStreams ? <Text size="sm" ta="center" my="md">Contagem de streams offline: {offStreams}</Text> :
                    <Stack align="center" mt="lg" spacing="lg">
                        <Loader/>
                        <Text size="sm">Aguarde enquanto a contagem de lives é carregada. Por favor, não feche este diálogo ou recarregue a página.</Text>
                    </Stack>}

                    {loadingStreamsEraser ?
                    <Stack align="center" mt="lg" spacing="lg">
                        <Loader/>
                        <Text size="sm">Aguarde enquanto as lives são deletadas...</Text>
                    </Stack> :
                    <></>}

                    <Group position="apart" mt="md">
                        <Button onClick={() => getOffStreamCounts()} style={{width:'45%'}}>Checar novamente</Button>
                        <Button onClick={() => eraseOffStreams()} style={{width:'45%'}}>Apagar lives offline</Button>
                    </Group>
                </Modal>

                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Grid mb="md">
                                <Grid.Col xs={12}>
                                    <Title order={2} c="brand.6">Streams Online</Title>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <Group position="left">
                                        <TextInput style={{width: 300, margin: 0}}
                                                   placeholder='Busque aqui pelo username'
                                                   onChange={e => setSearch(e.target.value)}/>
                                        <MediaQuery smallerThan={600} styles={{width:'100%'}}>
                                            <Button style={{width: 200, margin: 0}}
                                                    onClick={() => SearchItem()}> Buscar</Button>
                                        </MediaQuery>
                                    </Group>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <Group position="right">
                                        <Button style={{width: 230, margin: 0}}
                                                onClick={() => history.push('/streams/add')}> Adicionar Live Manual</Button>

                                        <Button style={{width: 230, margin: 0}}
                                                onClick={() => setModalAdd(!modalAdd)}> Adicionar Live Automática</Button>

                                        <Button style={{width: 230, margin: 0}}
                                                onClick={() => setModalState()}> Checar lives offline</Button>
                                    </Group>
                                </Grid.Col>
                            </Grid>

                            <DataTable theme="mosTheme" columns={columns}
                                       data={streams}
                                       pagination
                                       paginationServer
                                       paginationPerPage={20}
                                       progressPending={loading}
                                       paginationDefaultPage={page}
                                       paginationTotalRows={count}
                                       onChangePage={handlePageChange}
                                       paginationComponentOptions={{
                                           noRowsPerPage: true,
                                           rangeSeparatorText: 'de',
                                       }}
                            />
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Container>
        )
    }
}