import React, {useEffect, useState} from "react";
import {
    Grid,
    Paper,
    Group,
    ThemeIcon,
    Container,
    Button,
    MediaQuery,
    TextInput,
    Title,
    Avatar,
    Text,
    LoadingOverlay
} from "@mantine/core";
import "./style.scss";
import api from "../../services/api";
import {IconEye} from "tabler-icons";
import moment from "moment";
import DataTable from "react-data-table-component";



export default function Users({history}) {
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(true);
    let [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0)

    function getUsers(page) {
        api.get(`/admin/users?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            setUsers(response.data.data);
            setPage(response.data.page);
            setCount(response.data.total)
            setLoading(false);
        })
    }

    useEffect(() => {
        getUsers(1);
    }, []);


    function SearchItem() {
        setLoading(true);
        api
            .get(`/admin/users/search?term=${search}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                setLoading(false);
                setUsers(response.data.data);
                setPage(response.data.page);
            });
    }

    const handlePageChange = page => {
        getUsers(page);
    };


    const columns = [
        {
            name: 'Usuário Twitch',
            width:'300px',
            cell: row => {
                return (
                    <Group position="start">
                        <Avatar radius="xl" src={row.avatar_twitch}/>
                        <Text size="sm">{row.twitch_display_name}</Text>
                    </Group>
                )
            }
        },
        {
            name: 'E-mail',
            width:'350px',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Nível',
            selector: row => row.level,
            width:'100px'
        },
        {
            name: 'Moedas',
            selector: row => row.coins,
            sortable: true,
            right: true
        },
        {
            name: 'Experiência',
            selector: row => row.experience,
            sortable: true,
            right: true
        },
        {
            name: 'MMR',
            selector: row => row.score,
            right: true
        },
        {
            name: 'Cadastro',
            selector: row => moment(row.created_at).format("DD/MM/YYYY"),
            width: '150px',
        },
        {
            name: 'Ação',
            center: true,
            width: '200px',
            cell: row => {
                return (
                    <Group position="apart">
                        <ThemeIcon variant="light" size="lg" radius="xl" color="#ffffff"
                                   style={{cursor: 'pointer', boxShadow: `0px 5px 10px rgba(143, 149, 178, 0.1)`}}
                                   onClick={() => history.push(`/usuarios/${row.id}`)}>
                            <IconEye size={18} color="#8F95B2"/>
                        </ThemeIcon>
                    </Group>
                )
            }
        },
    ];

    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Grid mb="md">
                                <Grid.Col xs={12} md={6}>
                                    <Title order={2} c="brand.6">Usuários Cadastrados</Title>
                                </Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <Group position="right">
                                        <TextInput style={{width: 300, margin: 0}}
                                                      placeholder='Busque aqui pelo username ou e-mail'
                                                      onChange={e => setSearch(e.target.value)}/>
                                        <MediaQuery smallerThan={600} styles={{width:'100%'}}>
                                            <Button style={{width: 200, margin: 0}}
                                                    onClick={() => SearchItem()}> Buscar</Button>
                                        </MediaQuery>
                                    </Group>
                                </Grid.Col>
                            </Grid>

                            <DataTable theme="mosTheme" columns={columns}
                                       data={users}
                                       pagination
                                       paginationServer
                                       paginationPerPage={20}
                                       progressPending={loading}
                                       paginationDefaultPage={page}
                                       paginationTotalRows={count}
                                       onChangePage={handlePageChange}
                                       paginationComponentOptions={{
                                           noRowsPerPage: true,
                                           rangeSeparatorText: 'de',
                                       }}
                            />
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Container>
        )
    }
}