import React, {useEffect, useState} from "react";
import {
    Grid,
    Paper,
    Container,
    Title,
    TextInput,
    Image, LoadingOverlay
} from '@mantine/core'
import api from "../../../services/api";

export default function ShowStream({match, history}) {
    const twitchAccountId = match.params.id;
    let token = localStorage.getItem('authorization');
    const [loading, setLoading] = useState(true);
    const [streamData, setStreamData] = useState({});


    function getStreamDetails(){
        api.get(`/admin/streams/one/${twitchAccountId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then( response => {
            let data = response.data;
            let streamLink = response.data.thumbnail_url.replace(
                "{width}x{height}",
                "1280x720"
            );
            data.thumbnail_url = streamLink;
            setStreamData(data);
            setLoading(false);
        })
    }

    useEffect(() => {
        getStreamDetails()
    }, []);


    if (loading) {
        return <LoadingOverlay visible={loading} overlayBlur={2} />
    } else {
        return (
            <Container fluid>
                <Grid>
                    <Grid.Col xs={12}>
                        <Paper shadow="md" radius="md" p="md">
                            <Title order={2} style={{marginBottom:32}}>Detalhes da Stream</Title>
                            <Grid>
                                <Grid.Col xs={12} md={4}>
                                    <TextInput style={{marginBottom: 16}} label="Username Twitch" value={streamData.twitch_display_name} readOnly/>
                                </Grid.Col>
                                <Grid.Col xs={12} md={4}>
                                    <TextInput style={{marginBottom: 16}} label="Twitch Account Id" value={streamData.twitch_account_id} readOnly/>
                                </Grid.Col>
                                <Grid.Col xs={12} md={4}>
                                    <TextInput style={{marginBottom: 16}} label="Título da Live" value={streamData.title} readOnly/>
                                </Grid.Col>
                                <Grid.Col xs={12} md={4}>
                                    <TextInput style={{marginBottom: 16}} label="Jogo" value={streamData.game} readOnly/>
                                </Grid.Col>
                                <Grid.Col xs={12} md={4}>
                                    <TextInput style={{marginBottom: 16}} label="Id do Jogo" value={streamData.game_id} readOnly/>
                                </Grid.Col>
                                <Grid.Col xs={12} md={4}>
                                    <Image radius="sm" src={streamData.thumbnail_url} />
                                </Grid.Col>
                            </Grid>
                        </Paper>
                    </Grid.Col>
                </Grid>

            </Container>
        )
    }
}