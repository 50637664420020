import {notifications} from "@mantine/notifications";

export default function NotificationFactory(type, title, message){
    switch(type){
        case 'success':
            return(
                notifications.show({
                    withCloseButton: true,
                    title: title,
                    message: message,
                    color: 'teal'
                })
            )
        case 'error':
            return(
                notifications.show({
                    withCloseButton: true,
                    title: title,
                    message: message,
                    color: 'red'
                })
            )
        default:
            return null;
    }
}